import { render, staticRenderFns } from "./Paymentyes.vue?vue&type=template&id=dde60812&scoped=true"
var script = {}
import style0 from "./Paymentyes.vue?vue&type=style&index=0&id=dde60812&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dde60812",
  null
  
)

export default component.exports